import { NLandingPage } from './about-us.model';

const PREFIX = 'ABOUT_US';

export const INFORMATION_CARDS: NLandingPage.IInformationCards[] = [
  {
    label: `${PREFIX}.FEATURES.CONVENIENCE`,
    color: 'text-[#38BCE0]',
  },
  {
    label: `${PREFIX}.FEATURES.SECURITY`,
    color: 'text-[#01426A]',
  },
  {
    label: `${PREFIX}.FEATURES.FLEXIBILITY`,
    color: 'text-[#15FABC]',
  },
];

export const SUBSCRIPTIONS: NLandingPage.ISubsciptions[] = [
  {
    label: `${PREFIX}.PACKAGES.MONTHLY`,
    bgColor: 'white',
    color: 'shrl-blue-500',
    priceColor: 'shrl-cyan-500',
  },
  {
    label: `${PREFIX}.PACKAGES.QUARTELY`,
    bgColor: 'shrl-teal-500',
    color: 'shrl-blue-500',
  },
  {
    label: `${PREFIX}.PACKAGES.YEARLY`,
    bgColor: 'shrl-blue-500',
    color: 'white',
    priceColor: 'shrl-teal-500',
  },
];
