import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';

@Directive({
  selector: '[shrlSwiper]',
  standalone: true,
})
export class SwiperDirective implements AfterViewInit {
  @Input() autoSlides?: boolean;

  constructor(private el: ElementRef<SwiperContainer>) {}

  ngAfterViewInit(): void {
    Object.assign(
      this.el.nativeElement,
      this.autoSlides ? this.autoSlidesPerViewConfig : this.defaultConfig
    );
  }

  get defaultConfig(): SwiperOptions {
    return {
      spaceBetween: 10,

      breakpoints: {
        1024: {
          slidesPerView: 3,
        },
      },
      pagination: true,
    };
  }

  get autoSlidesPerViewConfig(): SwiperOptions {
    return {
      ...this.defaultConfig,
      slidesPerView: 'auto',
      centeredSlides: true,
      shortSwipes: true,
    };
  }
}
