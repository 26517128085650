import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { INFORMATION_CARDS, SUBSCRIPTIONS } from './about-us-data';
import { SharelockCommonModule } from '../../../../share/modules/sharelock-common.module';
import { MatButtonModule } from '@angular/material/button';
import { scrollTo } from '../../../../share/utils/behaviour.methods';
import { MatIconModule } from '@angular/material/icon';
import { SwiperDirective } from '../../../../share/directives/swiper.directive';
import { NLandingPage } from './about-us.model';
import { ENavigationRoute } from '../../../../core/model/navigation.model';
import { RouterLink } from '@angular/router';
import {
  updateDescriptionMetaTag,
  updateTitleMetaTag,
} from '../../../../share/utils/functions/functions';

@Component({
  selector: 'shrl-about-us',
  imports: [
    SharelockCommonModule,
    MatButtonModule,
    MatIconModule,
    SwiperDirective,
    RouterLink,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './about-us.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutUsComponent {
  readonly SUBSCRIPTIONS = SUBSCRIPTIONS;
  readonly INFORMATION_CARDS = INFORMATION_CARDS;
  readonly ENavigationRoute = ENavigationRoute;

  scrollTo = scrollTo;

  subscriptionPackagesCarousel: NLandingPage.IPackagesCarousel = {
    currentIndex: 0,
    config: {},
  };

  constructor() {
    this.#setMeta();
  }

  #setMeta() {
    updateTitleMetaTag('SHARELOCK - O nas', 'SHARELOCK - About');
    updateDescriptionMetaTag(
      'SHARELOCK to bezpieczny system zarządzania wynajmem nieruchomości, który zapewnia elastyczność i wygodę zarówno dla gospodarzy, jak i dla gości.',
      'SHARELOCK is a secure property rental management system that provides flexibility and convenience for both hosts and guests.'
    );
  }

  slideChange = (swiper: Event) =>
    (this.subscriptionPackagesCarousel.currentIndex = (
      swiper as CustomEvent
    ).detail[0].realIndex);
}
