<div class="font-sans">
    <section
        class="bg-[url('/assets/images/landing-page-header-bg-mobile.webp')] bg-cover bg-right bg-no-repeat py-12 md:bg-[url('/assets/images/landing-page-header-bg.webp')] md:bg-right md:py-60">
        <div
            class="flex w-11/12 rounded-br-[70px] bg-white/75 px-6 py-10 md:rounded-br-[150px] md:px-8 lg:w-9/12 xl:w-2/4 xl:py-24 xl:pl-[20%] xl:pr-8">
            <div class="container mx-auto">
                <h1
                    class="text-3.5xl font-medium uppercase leading-tight text-shrl-blue-500 xl:text-4.35xl">
                    {{ 'ABOUT_US.TITLE_SECTION.HEADING' | translate }}
                </h1>
                <div class="left text-base font-normal">
                    <p class="mb-4 mt-10">
                        {{ 'ABOUT_US.TITLE_SECTION.PARAGRAPH_1' | translate }}
                    </p>
                    <p class="mb-12 mt-12">
                        {{ 'ABOUT_US.TITLE_SECTION.PARAGRAPH_2' | translate }}
                    </p>
                </div>

                <button mat-flat-button (click)="scrollTo(lockIntro)" class="">
                    {{ 'ABOUT_US.TITLE_SECTION.BUTTON' | translate }}
                </button>
            </div>
        </div>
    </section>
    <section class="pb-0 pt-12 md:py-12" #lockIntro>
        <div
            class="mx-auto w-full px-8 sm:max-w-lg md:max-w-3xl md:px-4 lg:max-w-4xl xl:max-w-6xl">
            <div class="border-b border-gray-400 pb-12">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div>
                        <h2
                            class="mb-4 w-full text-3.5xl font-medium leading-tight text-shrl-blue-500 md:text-4.35xl xl:w-5/6">
                            {{ 'ABOUT_US.ABOUT.HEADING' | translate }}
                        </h2>
                        <p class="left my-10 w-full font-normal xl:w-5/6">
                            {{ 'ABOUT_US.ABOUT.PARAGRAPH' | translate }}
                        </p>
                    </div>
                    <div class="mt-4 md:mt-0 md:px-3.5">
                        <img
                            src="/assets/images/Sharelock_Product_Pic.webp"
                            alt="Door lock"
                            class="w-full" />
                    </div>
                </div>
            </div>
            <div class="mt-8 block xl:hidden">
                <swiper-container class="pb-20" shrlSwiper #swiper>
                    @for (card of INFORMATION_CARDS; track $index) {
                        <swiper-slide class="h-auto pb-14">
                            <div
                                class="mb-6 mt-2 h-full"
                                [ngClass]="{ 'pr-0 xl:pr-3.5': !$last }">
                                <div
                                    class="my-4 h-full rounded bg-white p-7 shadow-xl md:p-8">
                                    <h3
                                        class="mb-3 text-3xl font-medium md:mb-6 md:text-2xl"
                                        [ngClass]="card.color">
                                        {{ card.label + '.TITLE' | translate }}
                                    </h3>
                                    <p class="m-0">
                                        {{
                                            card.label + '.PARAGRAPH'
                                                | translate
                                        }}
                                    </p>
                                </div>
                            </div>
                        </swiper-slide>
                    }
                </swiper-container>
            </div>
            <div class="my-2 mt-12 hidden grid-cols-1 xl:grid xl:grid-cols-3">
                @for (card of INFORMATION_CARDS; track $index) {
                    <div class="my-2" [ngClass]="{ 'pr-0 xl:pr-3.5': !$last }">
                        <div class="my-4 h-full rounded bg-white p-8 shadow-xl">
                            <h4
                                class="mb-6 text-2xl font-medium"
                                [ngClass]="card.color">
                                {{ card.label + '.TITLE' | translate }}
                            </h4>
                            <p class="m-0">
                                {{ card.label + '.PARAGRAPH' | translate }}
                            </p>
                        </div>
                    </div>
                }
            </div>
        </div>
    </section>
    <section class="bg-shrl-slate-100 pb-24 pt-12 text-center">
        <div
            class="container mx-auto max-w-lg text-center md:flex md:max-w-6xl md:flex-col md:items-center">
            <h2
                class="mb-12 px-8 py-8 text-3xl font-medium text-shrl-blue-500 xl:px-56 xl:text-4.35xl">
                {{ 'ABOUT_US.PACKAGES.HEADING_PACKAGES' | translate }} <br />
                {{ 'ABOUT_US.PACKAGES.HEADING_FAIR' | translate }}
            </h2>
            <div class="block max-w-lg xl:hidden">
                <swiper-container
                    shrlSwiper
                    [autoSlides]="true"
                    #swiper
                    (swiperslidechange)="slideChange($event)">
                    @for (subscriptionCard of SUBSCRIPTIONS; track $index) {
                        <swiper-slide class="!w-fit pb-12">
                            <div
                                class="center mx-3 my-auto rounded px-12"
                                [ngClass]="[
                                    'bg-' + subscriptionCard.bgColor,
                                    'text-' + subscriptionCard.color,
                                    $index ===
                                    subscriptionPackagesCarousel.currentIndex
                                        ? 'py-10 shadow-xl'
                                        : 'mt-4 py-6',
                                ]">
                                <h3 class="mb-4 text-2xl font-medium">
                                    {{
                                        subscriptionCard.label + '.TITLE'
                                            | translate
                                    }}
                                </h3>
                                <p
                                    class="mb-4 text-5xl font-normal"
                                    [ngClass]="
                                        'text-' + subscriptionCard.priceColor
                                    ">
                                    {{
                                        subscriptionCard.label + '.PRICE'
                                            | translate
                                    }}
                                </p>
                                <p class="shrl-text-h5">
                                    {{
                                        'ABOUT_US.PACKAGES.PLUS_23_VAT'
                                            | translate
                                    }}
                                </p>
                            </div>
                        </swiper-slide>
                    }
                </swiper-container>
            </div>
            <div
                class="hidden w-full grid-cols-1 justify-center xl:grid xl:w-9/12 xl:grid-cols-3">
                @for (subscriptionCard of SUBSCRIPTIONS; track $index) {
                    <div
                        class="mx-3 my-auto rounded px-4 shadow-xl"
                        [ngClass]="[
                            'bg-' + subscriptionCard.bgColor,
                            'text-' + subscriptionCard.color,
                            !$first && !$last ? 'py-10' : 'py-6',
                        ]">
                        <h4 class="mb-4 text-2xl font-medium">
                            {{ subscriptionCard.label + '.TITLE' | translate }}
                        </h4>
                        <p
                            class="mb-4 text-5xl font-normal"
                            [ngClass]="'text-' + subscriptionCard.priceColor">
                            {{ subscriptionCard.label + '.PRICE' | translate }}
                        </p>
                        <p class="shrl-text-h5">
                            {{ 'ABOUT_US.PACKAGES.PLUS_23_VAT' | translate }}
                        </p>
                    </div>
                }
            </div>
        </div>
    </section>
    <section
        class="flex flex-col justify-center bg-shrl-teal-500 px-0 pt-20 text-center xl:px-[30%]">
        <div
            class="container mx-auto flex w-full max-w-lg flex-col items-center px-8 md:max-w-6xl md:px-0">
            <h2
                class="mb-8 text-3.5xl font-medium text-shrl-neutral-900 md:text-4.35xl md:font-bold">
                {{ 'ABOUT_US.FIND_OUT_MORE.HEADING' | translate }}
            </h2>
            <button
                mat-flat-button
                [routerLink]="ENavigationRoute.HOW_IT_WORKS"
                class="">
                {{ 'SHARED.GENERAL.CLICK_HERE' | translate }}
            </button>
        </div>
        <img
            class="h-80 object-contain px-4 pt-8 md:px-0"
            src="/assets/images/Sharelock_Laptop.webp"
            alt="Section 3 Image" />
    </section>
    <section class="bg-white py-20">
        <div
            class="container mx-auto max-w-lg px-2 text-center md:max-w-6xl md:px-0">
            <h2
                class="mb-4 px-6 text-3.5xl font-medium text-shrl-blue-500 md:px-0 md:text-4.35xl">
                {{ 'ABOUT_US.OUR_CUSTOMERS.HEADING' | translate }}
            </h2>
            <h2
                class="-mb-32 font-serif text-[15rem] leading-[100%] text-shrl-teal-500">
                &#8221;
            </h2>
            <div class="swiper-ref block xl:hidden">
                <swiper-container class="clients-swiper" shrlSwiper #swiper>
                    @for (customer of [1, 2, 3]; track $index) {
                        <swiper-slide class="h-auto pb-14">
                            <div class="h-full w-full px-3.5 xl:w-11/12">
                                <div
                                    class="mx-3 my-4 flex h-full flex-col items-center rounded bg-white p-12 shadow-xl">
                                    <img
                                        class="b-radius-max w-28 pb-3"
                                        [src]="
                                            '/assets/images/Customer_Pic' +
                                            ($index + 1) +
                                            '.png'
                                        "
                                        alt="testimonial-person-image" />
                                    <h3
                                        class="m-0 p-0 pt-2 text-left font-medium">
                                        {{
                                            'ABOUT_US.OUR_CUSTOMERS.CUSTOMERS.CUSTOMER_' +
                                                ($index + 1) +
                                                '.NAME' | translate
                                        }}
                                    </h3>
                                    <p class="mb-4 text-left text-gray-400">
                                        {{
                                            'ABOUT_US.OUR_CUSTOMERS.CUSTOMERS.CUSTOMER_' +
                                                ($index + 1) +
                                                '.TITLE' | translate
                                        }}
                                    </p>
                                    <p class="text-center xl:text-left">
                                        {{
                                            'ABOUT_US.OUR_CUSTOMERS.CUSTOMERS.CUSTOMER_' +
                                                ($index + 1) +
                                                '.TESTIMONAL' | translate
                                        }}
                                    </p>
                                </div>
                            </div>
                        </swiper-slide>
                    }
                </swiper-container>
            </div>
            <div
                class="justify-content-center hidden grid-cols-1 xl:grid xl:grid-cols-3">
                @for (customer of [1, 2, 3]; track $index) {
                    <div class="w-full px-3.5 xl:w-11/12">
                        <div
                            class="mx-3 my-4 flex flex-col items-center rounded bg-white p-12 shadow-xl">
                            <img
                                class="b-radius-max w-28 pb-3"
                                [src]="
                                    '/assets/images/Customer_Pic' +
                                    ($index + 1) +
                                    '.png'
                                "
                                alt="testimonial-person-image" />
                            <h5 class="m-0 p-0 pt-2 text-left font-medium">
                                {{
                                    'ABOUT_US.OUR_CUSTOMERS.CUSTOMERS.CUSTOMER_' +
                                        ($index + 1) +
                                        '.NAME' | translate
                                }}
                            </h5>
                            <p class="mb-4 text-left text-gray-400">
                                {{
                                    'ABOUT_US.OUR_CUSTOMERS.CUSTOMERS.CUSTOMER_' +
                                        ($index + 1) +
                                        '.TITLE' | translate
                                }}
                            </p>
                            <p class="text-center xl:text-left">
                                {{
                                    'ABOUT_US.OUR_CUSTOMERS.CUSTOMERS.CUSTOMER_' +
                                        ($index + 1) +
                                        '.TESTIMONAL' | translate
                                }}
                            </p>
                        </div>
                    </div>
                }
            </div>
        </div>
    </section>
</div>
